import React from 'react';
import Layout from '../atoms/layout';
import ListComponent from '../ListComponent';
import NewAboutIronhackItem from '../NewAboutIronhackItem';
import { Title2 } from '../atoms/headers';
import { News, LayoutNewsAboutIronhack } from './styles';
import { withTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import DatoNews from '../../services/datoNews';

const NewsAboutIronhack: React.SFC<any> = (props: {
  locale: string;
  t: TFunction;
}) => {
  const { locale, t } = props;
  const news = new DatoNews().getNewsByLocale(locale);

  return news.length ? (
    <Layout extend={LayoutNewsAboutIronhack}>
      <Title2 as="h2">{t('press:press.newsAboutIronhack')}</Title2>
      <News>
        <ListComponent
          listItems={news}
          ChildrenComponent={NewAboutIronhackItem}
        />
      </News>
    </Layout>
  ) : null;
};

export default withTranslation()(NewsAboutIronhack);
