import React from 'react';
import { NewsLink } from './styles';
import { Text2, Text5 } from '../atoms/body';
import PropsNewAboutIronhackItem from '../interfaces/NewAboutIronhackItem';

function NewAboutIronhackItem(props: PropsNewAboutIronhackItem) {
  const { headline, year, source, url } = props;

  return (
    <article>
      <NewsLink
        href={url}
        target="_blank"
        rel="nofollow noreferrer noopener"
        title={headline}
      >
        <Text2 as="h3">{headline}</Text2>
        <Text5 as="p">{`${year} -- ${source}`}</Text5>
      </NewsLink>
    </article>
  );
}

export default NewAboutIronhackItem;
