import React from 'react';
import { graphql } from 'gatsby';
import { Box, SimpleGrid, Text } from '@chakra-ui/react';

import withI18next from '../../i18n/TemplateI18n';
import PageTitle from '../../components/PageTitle';
import Header from '../../components/Header';
import PressReleases from '../../components/PressReleases';
import NewsAboutIronhack from '../../components/NewsAboutIronhack';
import Footer from '../../components/Footer';
import InternalLinks from '../../containers/InternalLinks';
import PressProps from '../../interface/template';
import useUserLocation from '../../hooks/useUserLocation';
import Banner from '../../components/Banner';

const Press: React.SFC<any> = (props: PressProps) => {
  const { t, pageContext } = props;
  const { locale, pageName } = pageContext;
  const { userLocation } = useUserLocation();

  return (
    <>
      {userLocation?.country === 'NL' && <Banner type="stap" />}
      <Header pageName={pageName} showBackground>
        <PageTitle
          subtitle={t('press:press.mainHeadline.subtitle')}
          title={t('press:press.mainHeadline.title')}
        />
      </Header>
      <PressReleases locale={locale} />
      <NewsAboutIronhack locale={locale} />
      <InternalLinks />
      {locale === 'de' && (
        <Box bg="denim.100" py={4}>
          <Box maxW="129rem" mx="auto">
            <Text color="white" textStyle="title2">
              {t('contact-form:contactForm.form.heading.highlight')}
            </Text>
            <SimpleGrid columns={[1, null, 2]} mt={3} spacing={1} maxW="60rem">
              <Text color="white" textStyle="body5">
                {t('press:press.contact.name')}
              </Text>
              <Text color="white" textStyle="body5">
                {t('press:press.contact.role')}
              </Text>
              <Text color="white" textStyle="body5">
                {t('press:press.contact.email')}
              </Text>
              <Text color="white" textStyle="body5">
                {t('press:press.contact.phone')}
              </Text>
            </SimpleGrid>
          </Box>
        </Box>
      )}
      <Footer page={pageName} />
    </>
  );
};

export const query = graphql`
  query ($locale: String!) {
    locales: allLocale(
      filter: {
        lng: { eq: $locale }
        ns: {
          regex: "/(contact-form)|(menu)|(press)|(seo)|(breadcrumbs)|(banner)|(navigation)/"
        }
      }
    ) {
      ...TranslationFragment
    }
  }
`;

export default withI18next()(Press);
