import styled, { css } from 'styled-components';
import config from '../config';

const News = styled.section`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  & > article {
    width: calc(50% - 3.2rem);
    margin-top: 3.5rem;
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    & > article {
      width: 100%;
      margin-top: 3.2rem;
    }
  }
`;

const LayoutNewsAboutIronhack = css`
  background-color: #f8f8f8;
  padding-top: 4.8rem;
  padding-bottom: 4.8rem;

  @media (max-width: ${config.resolutions.mobileL}) {
    padding-top: 3.2rem;
    padding-bottom: 6.4rem;
  }
`;

export { News, LayoutNewsAboutIronhack };
