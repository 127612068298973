import React from 'react';
import { TFunction } from 'i18next';
import { withTranslation } from 'react-i18next';

import Layout from '../atoms/layout';
import { Title2 } from '../atoms/headers';
import { Press, LayoutPress } from './styles';
import ListComponent from '../ListComponent';
import PressReleaesItem from '../PressReleasesItem';
import DatoPressRelease from '../../services/datoPressRelease';

function PressReleases(props: { t: TFunction }) {
  const { t, locale } = props;
  const pressReleases = new DatoPressRelease().getPressReleases(locale);

  return pressReleases ? (
    <Layout extend={LayoutPress}>
      <Title2 as="h2">{t('press:press.pressreleases')}</Title2>
      <Press>
        <ListComponent
          listItems={pressReleases}
          ChildrenComponent={PressReleaesItem}
        />
      </Press>
    </Layout>
  ) : (
    <></>
  );
}

export default withTranslation()(PressReleases);
