import styled, { css } from 'styled-components';
import config from '../config';

const Press = styled.section`
  display: flex;
  flex-wrap: wrap;

  & > section {
    margin-top: 3.4rem;
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    & > section {
      width: 100%;
      padding-bottom: 1.6rem;
      border-bottom: 0.1rem solid ${config.palette.darkBlueTransparent('0.1')};
    }
  }
`;

const LayoutPress = css`
  padding-bottom: 6.4rem;
`;

export { Press, LayoutPress };
