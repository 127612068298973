import { graphql, useStaticQuery } from 'gatsby';
import _ from 'lodash/fp';

import { webLanguage } from '../services/datoUtilities';

export default class DatoNews {
  private locale: string;
  private datoNews: AllNews;

  public constructor() {
    this.datoNews = this.getAllNews();
    this.news = _.flow(
      _.getOr([], 'edges'),
      _.map('node'),
      _.uniqBy('url'),
      _.sortBy('position'),
      _.reduce(
        (byLocale, { locale, headline, url, year, source, languages }) => {
          _.flow(
            _.map('code'),
            _.map(_.toLower),
            _.forEach(locale => {
              byLocale = _.update(
                locale,
                news =>
                  _.concat(
                    {
                      headline,
                      url,
                      year,
                      source
                    },
                    _.defaultTo([], news)
                  ),
                byLocale
              );
            })
          )(languages);

          return byLocale;
        },
        {}
      )
    )(this.datoNews);
  }

  public getNewsByLocale(locale: string): array {
    return _.getOr([], locale, this.news);
  }

  private getAllNews() {
    const { allDatoCmsNews } = useStaticQuery(
      graphql`
        query {
          allDatoCmsNews {
            edges {
              node {
                headline
                year
                source
                url
                languages {
                  code
                }
                position
              }
            }
          }
        }
      `
    );
    return allDatoCmsNews;
  }
}
