import styled from 'styled-components';
import config from '../config';

const NewsLink = styled.a`
  text-decoration: none;

  & > p {
    margin-top: 0.8rem;
    color: ${config.palette.darkBlueTransparent('0.8')};
  }
`;

export { NewsLink };
