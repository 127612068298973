import { graphql, useStaticQuery } from 'gatsby';
import _ from 'lodash/fp';

import { webLanguage } from './datoUtilities';
import urls from '../../url.json';
export default class DatoPressRelease {
  private pressReleasesByLocale;

  public constructor(locale: string) {
    const pressReleases = this.getAllPressReleases();
    this.pressReleasesByLocale = _.flow(
      _.groupBy('webLocale'),
      _.mapKeys(key => webLanguage(key))
    )(pressReleases);
  }

  public getPressReleases(locale) {
    return this.pressReleasesByLocale[locale];
  }

  private getAllPressReleases(): AllBlogInfo {
    const { allDatoCmsPressRelease } = useStaticQuery(
      graphql`
        query {
          allDatoCmsPressRelease {
            edges {
              node {
                id
                locale
                position
                headline
                content
                date
                slug
                extract
              }
            }
          }
        }
      `
    );
    const filteredNodes = allDatoCmsPressRelease.edges
      .filter((edge: { node }) => edge.node.headline)
      .map(edge => edge.node)
      .map(pressRelease => {
        pressRelease.webLocale = webLanguage(pressRelease.locale);
        pressRelease.url = `/${pressRelease.webLocale}/${
          urls.news[pressRelease.webLocale]
        }/${pressRelease.slug}`;
        return pressRelease;
      });
    return filteredNodes;
  }
}
