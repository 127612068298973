import React from 'react';
import moment from 'moment';

import '../../lib/moment.ts';
import { Item, ReadMoreLink } from './styles';
import { Text2, Text5 } from '../atoms/body';
import ArrowBlue from '../../../static/assets/press-releases/arrow-blue.svg';

function PressReleaesItem(props: {
  headline: string;
  url: string;
  extract: string;
  locale: string;
  date: string;
}) {
  const { headline, url, locale, extract, date } = props;
  return (
    <Item>
      <Text5 as="div">
        {moment(date)
          .locale(locale)
          .format('LL')}
      </Text5>
      <Text2>{headline}</Text2>
      <Text5 as="p">{extract}</Text5>
      <ReadMoreLink href={url}>
        <Text2>Read more</Text2>
        <ArrowBlue />
      </ReadMoreLink>
    </Item>
  );
}

export default PressReleaesItem;
